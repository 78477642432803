import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import FeatureWithImage from '../../components/FeatureWithImage'
import Layout from '../../components/Layout'

import react1Image from '../../img/pages/technologies/react-1.png'
import react2Image from '../../img/pages/technologies/react-2.png'
import react3Image from '../../img/pages/technologies/react-3.png'
import reactNativeImage from '../../img/pages/technologies/why-consider-react.png'

const TechnologyRow = styled(Row)`
  border-bottom: 1px solid #ccc;
  padding: 35px 0;
  &:last-child {
    border-bottom: none;
  }
`
const TechnologyHeader = styled.h3`
  padding-bottom: 15px;
  @media(max-width: 767px) {
    text-align: center;
  }
`
const TechnologyImageCol = styled(Col)`
  @media(max-width: 767px) {
    text-align: center;
    margin-bottom: 15px;
  }
`
const TechnologyItem = styled.p`
  position: relative; padding-left: 200px; margin: .3rem 0 0 0;
  @media screen and (max-width: 560px) {
    padding-left: 20px;
  }
  @media screen and (max-width: 960px) {
    padding-left: 120px;
  }
`
const TechnologyItemTitle = styled.span`
  position: absolute; left:0;top:0;display:block;width:165px;color:#1f1f1f;text-align:right;
  @media screen and (max-width: 960px) {
    width:100px;
  }
  @media screen and (max-width:560px) {
    position:relative; width:auto; margin-left: -20px;text-align: left;
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="technologies">
        <Helmet
          title="React.js Software Development | Bishop Software"
          meta={[
            { name: 'description', content: 'We use React to help businesses succeed and grow. Start your next web development project in React. Let\'s talk.' },
            { name: 'keywords', content: 'react,reactjs,redux,mobx,gatsbyjs,software,development,web,design,mobile,consultancy,consulting,website,application' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #09203A 0%, #207cca 73%, #6977B2 90%, #BC6591 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#1076B8 0px, #072944 100%)' section="technologies" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: 'rgb(243, 174, 189)', fontSize: '40px', fontWeight: 300}}>React.JS Development Company</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>We proudly built this site, and many others, with the React Javascript library.</h3>
              <div style={{marginTop: '35px', paddingBottom: '55px'}}>
                <Link to="/contact" className="round btn btn-primary">Hire our React Developers</Link>
              </div>
            </div>
          </Container>
        </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <p>React.js is probably the trendiest JavaScript open source library available today. Created by Facebook, it is used not only to make Facebook products but is also widely adopted in production by a variety of other popular companies like Instagram, Yahoo!, Airbnb, Sony, and others.</p>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center" style={{marginBottom: '45px'}}>Why React.js?</h1>
            <FeatureWithImage title="Component-driven design" imageSrc={react1Image}>With React.js you can easily split pages or features into smaller components that can be reused in other parts of a website. Generally, it means that a web app consists of separate, independent components, which include smaller components, and so on. In fact, this feature section you are looking at right now, is a component.</FeatureWithImage>
            <FeatureWithImage title="Benefits" imageSrc={react2Image} right={true}>Breaking an application down into separate modules can be beneficial both for the development and for the product in the long run. At the app development stage, it helps to design a smarter architecture that will be more tolerant to changes in business requirements, will allow paralleling jobs between many developers, and generally reduces the delivery time of a product.</FeatureWithImage>
            <FeatureWithImage title="Growth" imageSrc={react3Image}>Dynamic growth in the number of big projects built on React.js confirms that there is a future for web app creation on React.js. That’s why we increasingly apply this technology to new projects that we create.</FeatureWithImage>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(180deg, #fff 0, #fff 30%, #fefefe 90%, #eee 100%)'}}>
      <Container>
        <Row>
          <Col>
            <h1 className="text-center" style={{marginBottom: '45px'}}>Not Only for the Web</h1>
            <FeatureWithImage imageSrc={reactNativeImage} right={true}>
              <p>Facebook’s React Native is a trendy technology and is considered to be the future of hybrid application development. It’s the perfect framework to build native mobile applications. The use of React Native and JavaScript helps develop mobile apps for Android and iOS that are visually rich and functionally strong.</p>
              <p>React mobile apps have the speed, look, and feel as well as the functionality of native mobile applications. The combination of HTML and JavaScript allows React.js developers to write reusable code. The ability to reuse React components allows businesses to easily augment existing applications without any code rewriting. That reduces time and costs and allows for creating high-performance apps with appealing UIs.</p>
              <p>In fact, React Native is created to build fantastic mobile UIs which are highly responsive.</p>
            </FeatureWithImage>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="technologies" />
      </div>
  </Layout>
    )
  }
}
  